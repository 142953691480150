.c-sh-performance-across-assignments-chart {
  border: $els-thin-keyline-width solid $els-color-n3;
  border-top: $els-thick-keyline-width solid $els-color-info;
  box-shadow: 0 $els-thick-keyline-width $els-thick-keyline-width $els-color-n3;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $els-space;
  }
}
