.c-sh-assignment-view-details {
  &__wrapper {
    width: 100%;
    margin: auto;
    background-color: $els-color-n1;
    padding-left: $els-space-1x1o2;

    .c-sh-toggle-panel {
      &__group {
        @extend .u-max-width-1400;
        margin: auto;
      }
    }

    .o-els-container {
      border-top: $els-thin-keyline-width solid $els-color-n3;
      @extend .u-max-width-1400;
      margin: auto;
      padding: $els-space 0 0;
      background-color: $els-color-background;
    }

    .c-els-tab-group {
      @extend .u-max-width-1400;
      margin: auto;
      padding-left: $els-space-5x;

      &__content {
        padding: $els-space-1x1o4 calc(3.125 * #{$els-space-1x}) calc(5.125 * #{$els-space-1x}) calc(3.125 * #{$els-space-1x});

      }

      &__text {

        &:hover,
        &:active,
        &:focus {
          color: $els-color-text;
        }
      }

      &__item {
        &--active {
          .c-els-tab-group__text {
            color: $els-color-text;
          }
        }
      }
    }

  }

  &__see-more-dashboards {
    &-title {
      display: flex;
      align-items: center;
    }
  }
}
