@each $class, $color in $els-color-classes {
  .u-els-fill-#{$class} {
    fill: $color;
  }

  .u-els-stroke-#{$class} {
    stroke: $color;
  }

  .u-rechart-area-fill-#{$class} {
    .recharts-area-area {
      fill: $color;
    }
  }

  .u-rechart-area-stroke-#{$class} {
    .recharts-area-curve {
      stroke: $color;
    }
  }

  .u-rechart-reference-area-stroke-#{$class} {
    .recharts-reference-area-rect {
      stroke: $color;
    }
  }

  .u-rechart-reference-area-fill-#{$class} {
    .recharts-reference-area-rect {
      fill: $color;
    }
  }

  .u-rechart-reference-dot-fill-#{$class} {
    .recharts-reference-dot-dot {
      fill: $color;
    }
  }

  .u-recharts-line-#{$class} {
    .recharts-line-curve {
      stroke: $color;
    }
  }

  .u-recharts-line-dots-fill-#{$class} {
    .recharts-line-dot {
      fill: $color;
    }
  }

  .u-recharts-line-dots-stroke-#{$class} {
    .recharts-line-dot {
      stroke: $color;
    }
  }

  .u-recharts-reference-line-#{$class} {
    .recharts-reference-line-line {
      stroke: $color;
    }
  }

  .u-recharts-stop-color-#{$class} {
    stop-color: $color;
  }

  .u-border-#{$class} {
    border-color: $color !important;
  }
}

.u-els {
  &-stroke {
    &-custom-warn-1 { stroke: #8c271b; }
    &-custom-acceptable-1 { stroke: #1d7413; }
    &-custom-recommended-1 { stroke: #10420b; }
    &-extended-blue-11 { stroke: #0d5e7c; }
  }

  &-fill {
    &-custom-warn-1 { fill: #ebb1ab; }
    &-custom-warn-2 { fill: #d93725; }
    &-custom-warn-3 { fill: #972317; }
    &-custom-extended-green-1 { fill: #29a61b; }
    &-custom-extended-green-2 { fill: #b4e0af; }
    &-custom-acceptable-1 { fill: #bfe4bb; }
    &-custom-recommended-1 { fill: #1d7413; }
    &-custom-recommended-2 { fill: #a0bb7c; }
    &-color-info { fill: $els-color-info; }
    &-white { fill: #ffffff; }
    &-extended-blue-11 { fill: #0d5e7c; }
    &-n2 { fill: $els-color-n2; }
    &-n6 { fill: $els-color-n6; }
    &-extended-teal-7 { fill: $els-color-extended-teal-7; }
    &-extended-blue-9 { fill: $els-color-extended-blue-9; }
    &-warn { fill: $els-color-warn; }
    &-custom-green-1 { fill: #619913; }
    &-custom-green-4 { fill: #4f7b16; }
    &-elsevier-orange { fill: #ff6c00; }
    &-custom-blue-1 { fill: #177da0 !important; }
    &-custom-red-1 { fill: #b12c1e; }
    &-custom-red-2 { fill: #95241c !important; }
    &-custom-orange-2 { fill: #de8329 !important; }
    &-custom-orange-3 { fill: #e9711c; }
    &-custom-green-2 { fill: #77cd47 !important; }
    &-custom-green-3 { fill: #147a0c !important; }
    &-chart-grey-1 { fill: #e2e2e2; }
    &-chart-grey-2 { fill: #eeeeee; }
    &-chart-green-1 { fill: #9CCF8B; }
    &-chart-green-2 { fill: #5A9D4A; }
    &-chart-green-3 { fill: #157A0D; }
    &-custom-old-brick { fill: #95251B; }
    &-custom-bright-sun { fill: #FFC83E; }
  }
}

.u-custom {
  &-color {
    &-black-1 { color: #1a1a1a !important; }
    &-dark-green { color: #29a61b !important; }
    &-green-1 { color: #29a61b; }
    &-orange-1 { color: #e9711d; }
    &-orange-3 { color: #e9711c; }
    &-dark { color: #000; }
    &-dark-2 { color: #2e2e2e; }
    &-dark-3 { color: #494949 !important; }
  }

  &-bg-color {
    &-pink-1 { background-color: #faebe9; }
    &-san-felix { background-color: #29a61b; }
    &-old-brick { background-color: #95251b; }
    &-serenade { background-color: #fff0e4; }
    &-bizarre { background-color: #EFDEDC; }
    &-citrine-white { background-color: #FAEADC; }
    &-frost { background-color: #E7F6DF; }
    &-tea-green { background-color: #C7EDC5; }
    &-orange-2 { background-color: #de8329 !important; }
    &-green-2 { background-color: #77cd47 !important; }
    &-dark-blue-1 { background-color: #0b4768; }
    &-gray-1 { background-color: #e2e2e2; }
    &-gray-2 { background-color: #eeeeee !important; }
    &-red-1 { background-color: #b12c1e !important; }
    &-red-2 { background-color: #b12c1e; }
    &-red-3 { background-color: #f3dfdd; }
    &-red-4 { background-color: #95241c; }
    &-orange-1 { background-color: #fdf1e8 !important; }
    &-orange-2 { background-color: #de8329; }
    &-orange-3 { background-color: #e9711c !important; }
    &-green-1 { background-color: #ecf9dc !important; }
    &-green-2 { background-color: #f5fbf4; }
    &-green-3 { background-color: #4f7b16 !important; }
    &-green-4 { background-color: #77cd47; }
    &-green-5 { background-color: #147a0c; }
    &-blue-1 { background-color: #eaf9ff !important; }
    &-blue-2 { background-color: #3c6c86 !important; }
    &-blue-3 { background-color: #f2fafd; }
    &-blue-4 { background-color: #2b6589; }
    &-blue-9 { background-color: #ccf3ff; }
  }

  &-border {
    &-dark-green { border-color: #29a61b; }
    &-color-gray-1 { border-color: #d4d4d4 !important; }
  }
}